<template>
  <b-row ref="container">
    <b-col md='12'>
      <table-header
        v-bind="{
          minDate,
          maxDate,
          dateFrom: date_from,
          initialDate: date_from,
          createTitle: !hasPermission
          ? 'Создать заказ' : '',
          useContext: true,
        }"
        @create="onOrderCreate"
        @reset="onResetFilter"
        @update="onUpdateFilter"
      >

        <template #filters>
          <b-col
            class="mb-1 mb-md-0"
            lg=2
            md=6
            sm=12
          >
            <common-select
              v-bind="{
                name: 'courier_id',
                value: courier_id,
                options: couriers,
                placeholder: 'Курьер',
                valueField: 'id',
                textField: 'full_name'
              }"
              @change="onUpdateFilter"
            />
          </b-col>
        </template>
      </table-header>
    </b-col>
    <b-col md='12'>
      <b-card
        v-scroll="onScroll"
        no-body
      >
        <!-- calendar header -->
        <b-card-header class="d-none d-md-block d-md-flex">
          <b-button
            v-bind="{
            variant:'accent',
          }"
            @click="onChangeDay({ direction: 'prev' })"
          >
            <feather-icon v-bind="{
               icon: 'ChevronLeftIcon',
                size: '22',
                cursor: 'pointer',
              }"/>
          </b-button>
          <template v-for="(date, index) of timeline">
            <b-button
              :key="index"
              v-bind="{
                variant: index === active_day ? 'danger' : 'accent',
                size: 'sm',
                active: true,
                pill: true,
              }"
              @click="onPickDay({ date, index })"
            ><span :style="setLite({ date }) ? 'color: rgba(250, 250, 250, .8); font-weight: 600;' : ''">{{
                $dayjs(date).format('dd DD')
              }}</span>
            </b-button>
          </template>
          <b-button
            v-bind="{
            variant:'accent',
          }"
            @click="onChangeDay({ direction: 'next' })"
          >
            <feather-icon v-bind="{
                icon: 'ChevronRightIcon',
                size: '22',
                cursor: 'pointer',
              }"/>
          </b-button>
        </b-card-header>
        <!-- calendar header -->

        <!-- calendar body -->
        <b-card-body>
          <b-row>
            <swiper
              ref="ordersSwiper"
              :options="swiperOptions"
              :cleanup-styles-on-destroy="false"
            >
              <swiper-slide
                v-for="(option,index) of options" :key="index">
                <b-col
                  class="w-100 d-block mt-3 one-slide"
                  v-bind:class="{'d-none': option.value !== active }"
                >
                  <!-- calendar item  -->
                  <b-card no-body>
                    <b-card-title class="pt-2 pt-md-0">
                      <b-badge
                        class="d-flex justify-content-center pb-1 pt-1"
                        v-bind="{
                        variant: option.variant
                      }"
                      >
                        {{ option.text }}
                      </b-badge>
                    </b-card-title>
                    <b-card-text class="mb-0">
                      <template v-for="order of getOptions(option.status)">
                        <calendar-order-card
                          :key="order.id"
                          v-bind="{ order }"
                          @updateOrder="onUpdateOrder"
                        >
                        </calendar-order-card>
                      </template>
                    </b-card-text>
                  </b-card>
                  <!-- calendar item  -->
                </b-col>
              </swiper-slide>
              <div slot="scrollbar" class="swiper-scrollbar"></div>
            </swiper>


          </b-row>
        </b-card-body>
        <!-- calendar body -->
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { mapActions, mapGetters, mapState } from 'vuex'
import { throttle, debounce } from 'lodash'
import { duration, timeout } from '@/config'
import { StaffNameSpace, StaffActionTypes } from '@/store/modules/staff/types'
import {
  CalendarNameSpace,
  CalendarActionTypes,
  CalendarGetterTypes,
  CalendarOrderStatus,
} from '@/store/modules/calendar/types'
import { orderStatusCollection } from '@/config/shared'
import { cloneDeep } from 'lodash'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'CalendarOrder',
  components: {
    TableHeader: () => import('@/components/common/table/TableHeader.vue'),
    CalendarOrderCard: () => import('@/components/calendar/CalendarOrderCard.vue'),
    CommonSelect: () => import('@/components/common/common-select.vue'),
    Swiper,
    SwiperSlide,
  },

  props: {
    days: {
      type: Number,
      default() {
        return 7
      },
    },
  },

  data() {
    return {
      courier_id: null,
      status: '',
      page_size: 10,
      search: '',
      page: 1,
      timeline: [],
      active_day: 0,
      selected: '',
      active: 1,
      date_from: new Date(),
      timer: {
        id: '',
        timeout: 30000,
      },
      city_id: this.active_city,

      swiperOptions: {
        slidesPerView: 1,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          992: {
            slidesPerView: 2,
          },
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
      },
    }
  },

  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
      active_city: 'city_id',
    }),
    ...mapState(CalendarNameSpace, {
      orders: 'orders',
      pageCount: 'pageCount',
      minDate: 'date_from',
      maxDate: 'date_to',
    }),
    ...mapState(StaffNameSpace, {
      couriers: 'staffCollection',
    }),
    ...mapGetters(CalendarNameSpace, {
      newOrderCollection: [CalendarGetterTypes.GetNewOrderCollection],
    }),
    roles() {
      return RoleCollection
    },
    options() {
      return this.userData.role === this.roles.florist ? orderStatusCollection.slice(0, -3) : orderStatusCollection.slice(0, -1)
    },
    statuses() {
      return CalendarOrderStatus
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
    swiper() {
      return this.$refs.ordersSwiper.$swiper;
    },
  },
  methods: {
    ...mapActions(CalendarNameSpace, {
      [CalendarActionTypes.LoadCalendarCollection]: CalendarActionTypes.LoadCalendarCollection,
    }),

    ...mapActions(StaffNameSpace, {
      [StaffActionTypes.LoadStaffCollection]: StaffActionTypes.LoadStaffCollection,
    }),

    loadCollection: throttle(async function() {
      await this[CalendarActionTypes.LoadCalendarCollection]({
        ...this.$data,
      })
    }, duration),

    loadCourierCollection: throttle(async function() {
      await this[StaffActionTypes.LoadStaffCollection]({ role: 'courier', page_size: 50, city_id: this.active_city })
    }, duration),

    getOptions(key) {
      return this.orders[key] ? this.orders[key] : [];
    },

    async onPickDay({ index, date }) {
      this.active_day = index
      this.date_from = date
      await this.loadCollection()
    },

    async onUpdateOrder() {
      await this.loadCollection()
    },

    async onChangeDay({ activeDate, direction = '' } = {}) {
      // Список дней недели с понедельника по воскр
      const daysAfterMonday = this.date_from.getDay() ? this.date_from.getDay() - 1 : 6
      let mondayDate = cloneDeep(this.date_from)

      if (activeDate) {
        mondayDate = new Date(mondayDate.setDate(this.date_from.getDate() - daysAfterMonday))

        this.timeline = [
          ...new Array(this.days).fill(mondayDate).map(($date, $day) => {
            const day = cloneDeep($date)
            return !$day ? new Date(day) : new Date(day.setDate(day.getDate() + $day))
          }),
        ]

        const equalDates = el => el.getTime() === activeDate.getTime();
        const active_day = this.timeline.findIndex(equalDates)
        this.date_from = new Date(activeDate)
        this.active_day = active_day !== -1 ? active_day : this.active_day
      }

      if (direction === 'prev') {
        const date = this.date_from.setDate(this.timeline[0].getDate() - 7)
        this.date_from = new Date(date)
        this.active_day = 0
      }

      if (direction === 'next') {
        let date = null
        if (this.date_from.getDate() - daysAfterMonday >= 0) {
          date = this.date_from.setDate(this.timeline[0].getDate() + 7)
        } else {
          // Решает проблему перехода на новый месяц при выбранной дате нового месяца - перескакивает через месяц
          const equalDates = el => el.getTime() === this.date_from.getTime();
          const dayIndex = this.timeline.findIndex(equalDates)
          if (this.date_from.getDay()) {
            date = this.date_from.setDate(this.timeline[dayIndex].getDate() + (8 - this.date_from.getDay()))
          } else {
            date = this.date_from.setDate(this.timeline[dayIndex].getDate() + 1)
          }
        }
        this.date_from = new Date(date)
        this.active_day = 0
      }

      await this.loadCollection()
    },

    onOrderCreate() {
      this.$router.push({ name: 'order-base-new' })
    },

    async onUpdateFilter(context) {
      Object.assign(this.$data, context)
      this.page = 1

      if (context.date_from) {
        this.onChangeDay({ activeDate: context.date_from })
      }

      await this.loadCollection()
    },

    async onResetFilter() {
      this.courier_id = null
      this.search = ''
      this.date_from = new Date()

      await this.loadCollection()
    },

    onScroll: debounce(async function(e) {
      if (!e) return
      const { scrollHeight, clientHeight, scrollTop } = e.target.documentElement
      const offset = 200

      if (scrollTop + offset > scrollHeight - clientHeight) {
        this.page_size += 10
        await this.loadCollection()
      }
    }, timeout),
    setLite({ date }) {
      if (this.timeline[0].getMonth() !== date.getMonth()) {
        return false
      } else return true
    },

    addResizeHandler() {
      const observer = new ResizeObserver(() => {
        this.swiper?.update();
      });

      const el = this.$refs.container;
      observer.observe(el)
    },
  },

  async created() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.onScroll, true)
      this.onScroll()
    })

    this.onChangeDay()

    await this.loadCourierCollection()

    this.timer.id = setInterval(
        async function({ timer, loadCollection }) {
          if (timer.timeout > 0) {
            timer.timeout -= 1
            await loadCollection()
          } else {
            timer.timeout = timeout
            await loadCollection()
          }
        },
        this.timer.timeout,
        this,
    )
  },

  mounted() {
    this.addResizeHandler();
  },

  beforeDestroy() {
    clearInterval(this.timer.id)
    window.removeEventListener('scroll', this.onScroll, true)
  },

  watch: {
    async active_city(city_id) {
      this.city_id = city_id
      await this.loadCollection()
      await this.loadCourierCollection()
    },
  },
}
</script>

<style scoped>
.one-slide {
  min-width: 30vw;
}

.swiper-scrollbar {
  position: absolute;
  top: 10px;
}
</style>
